import axios from "axios"
const callApi= {
   getData: async (api)=>{
    try{
      const res=await axios.get("https://api.healthcareqmedic.in"+api,{
        headers:{
          access_token:window.localStorage.getItem('access_token')
        },
      withCredentials:true,
     
    });
    return res;
    }catch(err){ 
      return false;
    }
  },
  postData: async (api,data)=>{
    try{
      const res=await axios.post("https://api.healthcareqmedic.in"+api,data,{
      withCredentials:true,
      headers:{
        access_token:window.localStorage.getItem('access_token'),
        "Content-Type": "multipart/form-data",
      }
    });
    return res;
    }catch(err){ 
      console.log(err.response,err.message)
      return false;
    }
  },
  deleteData: async (api,data)=>{
    try{
      const res=await axios.delete("https://api.healthcareqmedic.in"+api,{
      withCredentials:true,
      headers:{
        access_token:window.localStorage.getItem('access_token')
      }
    });
    return res;
    }catch(err){ 
      return false;
    }
  },
  deleteOne: async (api)=>{
    try{
      const res=await axios.delete("https://api.healthcareqmedic.in"+api,{
      withCredentials:true,
      headers:{
        access_token:window.localStorage.getItem('access_token')
      }
    });
    return res;
    }catch(err){ 
      return false;
    }
  },
  updateData: async (api,data)=>{
    try{
      const res=await axios.put("https://api.healthcareqmedic.in"+api,data,{
      withCredentials:true,
      headers:{
        access_token:window.localStorage.getItem('access_token')
      }
    });
    return res;
    }catch(err){ 
      return false;
    }
  },
  updateOne: async (api)=>{
    try{
      const res=await axios.put("https://api.healthcareqmedic.in"+api,{
      withCredentials:true,
      headers:{
        access_token:window.localStorage.getItem('access_token')
      }
    });
    return res;
    }catch(err){ 
      return false;
    }
  },
}
const {postData,getData,updateData,deleteData,updateOne,deleteOne}=callApi
export {postData,getData,updateData,deleteData,updateOne,deleteOne}
